import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { config } from '../../../config/index';
import { urlConstants } from '../../../constants/urlConstants';

export const areaOfResidenceApi = createApi({
    reducerPath: 'areaOfResidenceApi',
    baseQuery: fetchBaseQuery({
        baseUrl: config().BASE_URL,
        prepareHeaders: (headers) => {
            headers.set("Authorization", `Token ${localStorage.getItem('cw-token')}`);
            return headers;
        },
    }),
    tagTypes: ['AreaOfResidence'],
    endpoints: (builder) => ({
        addAreaOfResidence: builder.mutation({
            query: (body) => ({
                url: `${urlConstants.AREAOFRESIDENT_ADD}`,
                method: 'POST',
                body,
            }),
            invalidatesTags: ['AreaOfResidence'],
        }),
        updateAreaOfResidence: builder.mutation({
            query: ({ id, body }) => ({
                url: `${urlConstants.AREAOFRESIDENT}/${id}`,
                method: 'PUT',
                body,
            }),
            invalidatesTags: ['AreaOfResidence'],
        }),
        getSingleAreaOfResidence: builder.query<any, any>({
            query: (id : any) => ({
                url: `${urlConstants.AREAOFRESIDENT}/${id}`,
                method: 'GET',
            }),
        }),
        listAreaOfResidence: builder.query({
            query: () => `${urlConstants.AREAOFRESIDENT_LIST}`,
            providesTags: ['AreaOfResidence'],
        }),
        deleteAreaOfResidence: builder.mutation<any, any>({
            query: (id: any) => ({
                url: `${urlConstants.AREAOFRESIDENT}/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['AreaOfResidence'],
        }),
    }),
});
export const {
    useAddAreaOfResidenceMutation,
    useUpdateAreaOfResidenceMutation,
    useGetSingleAreaOfResidenceQuery,
    useListAreaOfResidenceQuery,
    useDeleteAreaOfResidenceMutation
} = areaOfResidenceApi;

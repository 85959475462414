import React from 'react';
import "bootstrap/dist/css/bootstrap.min.css"
import './scss/common.scss';
import './App.css';
import { Routes, Route } from "react-router-dom"
import { Suspense } from 'react';
import Loading from './pages/miscellanious/loading'
import { PATH } from './constants/path';
import 'react-responsive-pagination/themes/classic.css';
import { useAppSelector } from './base/hooks/hooks';
// import AcquisitionFormMainSuccess from './pages/acquisition/acquisitionFormMainSuccess/acquisitionFormMainSuccess';

const Header = React.lazy(() => import('./layouts/customerLayout/index'))
const AccessDenied = React.lazy(() => import('./pages/miscellanious/accessDenied/accessDenied'))
const NotFound = React.lazy(() => import('./pages/miscellanious/notFound'))
const Login = React.lazy(() => import('./pages/login/login'))
const Home = React.lazy(() => import('./pages/home/index'))
const AcquisitionForm = React.lazy(() => import('./pages/acquisition/acquisitionForm/acquisitionForm'))
const AcquisitionFormMain = React.lazy(() => import('./pages/acquisition/acquisitionFormMain/acquisitionFormMain'))
const AcquisitionList = React.lazy(() => import('./pages/acquisition/acquisitionList/acquisitionList'))
const AddUser = React.lazy(() => import('./pages/users/addUser/addUser'))
const UserCreationForm = React.lazy(() => import('./pages/users/usersList/usersList'))
const DepartmentAdd = React.lazy(() => import('./pages/department/departmentAdd/departmentAdd'))
const DepartmentList = React.lazy(() => import('./pages/department/departmentList/departmentList'))
const OccupationList = React.lazy(() => import('./pages/occupation/occupationList/occupationList'))
const OccupationAdd = React.lazy(() => import('./pages/occupation/occupationAdd/occupationAdd'))
const AgeList = React.lazy(() => import('./pages/age/ageList/ageList'))
const AgeAdd = React.lazy(() => import('./pages/age/ageAdd/ageAdd'))
const KnowAboutUs = React.lazy(() => import('./pages/sourceOfInfo/knowAboutUsList/knowAboutUs'))
const KnowAboutUsAdd = React.lazy(() => import('./pages/sourceOfInfo/knowAboutUsAdd/KnowAboutUsAdd'))
const RoleAdd = React.lazy(() => import('./pages/role/createRole/roleAdd'))
const RoleList = React.lazy(() => import('./pages/role/rolesList/roleList'))
const PreferredUnitsList = React.lazy(() => import('./pages/preferredUnit/preferredUnitsList/preferredUnitsList'))
const PreferredUnitsAdd = React.lazy(() => import('./pages/preferredUnit/preferredUnitsAdd/preferredUnitsAdd'))
const PreferredBudgetAdd = React.lazy(() => import('./pages/budget/preferredBudgetRangeAdd/preferredBudgetRangeAdd'))
const PreferredBudgetRangeList = React.lazy(() => import('./pages/budget/preferredBudgetRangeList/preferredBudgetRangeList'))
const PreferredSizeAdd = React.lazy(() => import('./pages/preferredSize/preferredSizeAdd/preferredSizeAdd'))
const PreferredSizeList = React.lazy(() => import('./pages/preferredSize/preferredSizeList/preferredSizeList'))
const PurposeOfPurchaseAdd = React.lazy(() => import('./pages/purposeOfPurchase/purposeOfPurchaseAdd/purposeOfPurchaseAdd'))
const PurposeOfPurchaseList = React.lazy(() => import('./pages/purposeOfPurchase/purposeOfPurchaseList/purposeOfPurchaseList'))
const ProjectAdd = React.lazy(() => import('./pages/project/projectAdd/projectAdd'))
const ProjectList = React.lazy(() => import('./pages/project/projectList/projectList'))
const FieldEmloyees = React.lazy(() => import('./pages/fieldEmployeess/fieldEmployees/fieldEmployees'))
const FieldEmloyeesList = React.lazy(() => import('./pages/fieldEmployeess/fieldEmployeesList/fieldEmployeesList'))
const ReportList = React.lazy(() => import('./pages/reportList/reportList'))
const CustomAlert = React.lazy(() => import('./pages/common/customAlert/customAlert'))
const ExistingLead = React.lazy(() => import('./pages/acquisition/ExistingLead/existingLead'))
const AcquisitionFormMainSuccess = React.lazy(() => import('./pages/acquisition/acquisitionFormMainSuccess/acquisitionFormMainSuccess'))
const AcquisistionFeedback = React.lazy(() => import('./pages/acquisition/acquisitionFeedback/acquisistionFeedback'))
const CustomerReviewFormList = React.lazy(() => import('./pages/customerReviewForm/customerReviewFormList/customerReviewFormList'))
const CustomerReviewFormAdd = React.lazy(() => import('./pages/customerReviewForm/customerReviewFormAdd/customerReviewFormAdd'))
const CustomerReviewFormSuccess = React.lazy(() => import('./pages/acquisition/acquisitionFeedbackSuccess/acquisitionReviewFormMainSuccess'))
const CustomerReviewReport = React.lazy(() => import('./pages/customerReviewReport/customerReviewReport'))
const AreaOfCurrentResidentList = React.lazy(() => import('./pages/areaOfCurrentResidence/listAreaOfCurrentResidence/listAreaOfCurrentResidence'))
const AreaOfCurrentResidentAdd = React.lazy(() => import('./pages/areaOfCurrentResidence/addAreaOfCurrentResidence/addAreaOfCurrentResidence'))


function App() {
  const { messageNotify } = useAppSelector((state) => state.alertReducer)
  return (
    <div className="App">
      <Suspense fallback={<Loading />}>
        {messageNotify ? <CustomAlert /> : null}
        <Routes>
          <Route path={PATH.LOGIN} element={<Login />} />
          <Route path="*" element={<NotFound />} />
          <Route path={PATH.NOT_FOUND} element={<NotFound />} />
          <Route path={PATH.ACQUISITION_FORM_MAIN} element={<AcquisitionFormMain />} />
          <Route path={PATH.ACQUISITION_FORM_MAIN_SUCCESS} element={<AcquisitionFormMainSuccess />} />
          <Route path={PATH.ACQUISITION_FEEDBACK} element={<AcquisistionFeedback />} />
          <Route path={PATH.CUSTOMER_REVIEW_FORM_SUCCESS} element={<CustomerReviewFormSuccess />} />
          <Route element={<Header />}>
            <Route path={PATH.ACQUISITION_TYPE} element={<AcquisitionForm />} />
            <Route path={PATH.ACQUISITION_LIST_TYPE} element={<AcquisitionList />} />
            <Route path={PATH.DASHBOARD} element={<Home />} />
            <Route path={PATH.USERS_LIST} element={<UserCreationForm />} />
            <Route path={PATH.USER_TYPE} element={<AddUser />} />
            <Route path={PATH.DEPARTMENT_TYPE} element={<DepartmentAdd />} />
            <Route path={PATH.DEPARTMENT_LIST} element={<DepartmentList />} />
            <Route path={PATH.OCCUPATION_LIST} element={<OccupationList />} />
            <Route path={PATH.OCCUPATION_TYPE} element={<OccupationAdd />} />
            <Route path={PATH.AGE_LIST} element={<AgeList />} />
            <Route path={PATH.AGE_TYPE} element={<AgeAdd />} />
            <Route path={PATH.KNOW_ABOUT_US_LIST} element={<KnowAboutUs />} />
            <Route path={PATH.KNOW_ABOUT_US_TYPE} element={<KnowAboutUsAdd />} />
            <Route path={PATH.ROLE_TYPE} element={<RoleAdd />} />
            <Route path={PATH.ROLES_LIST} element={<RoleList />} />
            <Route path={PATH.PREFERRED_UNITS_LIST} element={<PreferredUnitsList />} />
            <Route path={PATH.PREFERRED_UNITS_TYPE} element={<PreferredUnitsAdd />} />
            <Route path={PATH.PREFERRED_BUDGET_RANGE_TYPE} element={<PreferredBudgetAdd />} />
            <Route path={PATH.PREFERRED_BUDGET_RANGE_LIST} element={<PreferredBudgetRangeList />} />
            <Route path={PATH.PREFERRED_SIZE_TYPE} element={<PreferredSizeAdd />} />
            <Route path={PATH.PREFERRED_SIZE_LIST} element={<PreferredSizeList />} />
            <Route path={PATH.PURPOSE_OF_PURCHASE_TYPE} element={<PurposeOfPurchaseAdd />} />
            <Route path={PATH.PURPOSE_OF_PURCHASE_LIST} element={<PurposeOfPurchaseList />} />
            <Route path={PATH.PROJECT_TYPE} element={<ProjectAdd />} />
            <Route path={PATH.PROJECT_LIST} element={<ProjectList />} />
            <Route path={PATH.FIELD_EMPLOYEES_TYPE} element={<FieldEmloyees />} />
            <Route path={PATH.FIELD_EMPLOYEES_LIST} element={<FieldEmloyeesList />} />
            <Route path={PATH.REPORT_LIST} element={<ReportList />} />
            <Route path={PATH.CUSTOMER_REVIEW_REPORT} element={<CustomerReviewReport />} />
            <Route path={PATH.ACCESS_DENIED} element={<AccessDenied />} />
            <Route path={PATH.EXISTING_LEAD} element={<ExistingLead />} />
            <Route path={PATH.CUSTOMER_REVIEW_FORM_LIST} element={<CustomerReviewFormList />} />
            <Route path={PATH.CUSTOMER_REVIEW_FORM_ADD} element={<CustomerReviewFormAdd />} />
            <Route path={PATH.CUSTOMER_REVIEW_FORM_TYPE} element={<CustomerReviewFormAdd />} />
            <Route path={PATH.AREAOFRESIDENT_ADD} element={<AreaOfCurrentResidentAdd />} />
            <Route path={PATH.AREAOFRESIDENT_LIST} element={<AreaOfCurrentResidentList />} />
            <Route path={PATH.AREAOFRESIDENT_TYPE} element={<AreaOfCurrentResidentAdd />} />
            
          </Route>
        </Routes>
      </Suspense>

    </div>
  );
}

export default App;

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { config } from '../../../config/index'
import { urlConstants } from '../../../constants/urlConstants'

export const AquisitionApi = createApi({
    reducerPath: 'AquisitionApi',
    baseQuery: fetchBaseQuery({
        baseUrl: config().BASE_URL,
        prepareHeaders: (headers: any) => {
            headers.set("Authorization", `Token ${localStorage.getItem('cw-token')}`)
            return headers
        }
    }),
    tagTypes: ['aquisition', 'aquisition-dropdown'],
    endpoints: (builder) => ({
        createAcquisition: builder.mutation<any, any>({
            query: (acquisitionData: any) => ({
                url: urlConstants.AQUISITION,
                method: 'POST',
                body: acquisitionData
            }),
            invalidatesTags: ['aquisition']
        }),
        getAquisitionDropdownsDetails: builder.query<any, void>({
            query: () => ({
                url: urlConstants.AQUISITION_DROPDOWN,
                method: 'GET',
            }),
            providesTags: ['aquisition']
        }),
        getUserSalesDropdownsDetails: builder.query<any, void>({
            query: () => ({
                url: urlConstants.SALES_DROPDOWN,
                method: 'GET',
            }),
            providesTags: ['aquisition-dropdown']
        }),
        getAcquisitionList: builder.query<any, any>({
            query: ({ currentPage, startDate, endDate, svStatus, searchType, searchString }: any) => {
                const params = {
                    page: currentPage,
                    fromDate: startDate,
                    toDate: endDate,
                    svStatus,
                    ...(searchType === 'customer' && { searchString }),
                    ...(searchType === 'fls' && { fls: searchString }),
                    ...(searchType === '' && { searchString }),
                };

                return {
                    url: urlConstants.AQUISITION_LIST,
                    method: 'GET',
                    params,
                };
            },
        }),

        editAcquisition: builder.mutation<any, any>({
            query: ({ acquisitionData, id }: any) => ({
                url: `${urlConstants.CUSTOMER}/${id}`,
                method: 'PUT',
                body: acquisitionData
            }),
            invalidatesTags: ['aquisition']
        }),
        editAcquisitionSF: builder.mutation<any, any>({
            query: ({ acquisitionData, id }: any) => ({
                url: `${urlConstants.UPDATE_AQUISITOIN_SF}/${id}`,
                method: 'PUT',
                body: acquisitionData
            }),
            invalidatesTags: ['aquisition']
        }),
        getSingleCustomer: builder.query<any, { id: string }>({
            query: (id) => ({
                url: `${urlConstants.CUSTOMER}/${id}`,
                method: 'GET',
            }),
            providesTags: ['aquisition']
        }),
        deleteAcquisition: builder.mutation<any, any>({
            query: (id: any) => ({
                url: `${urlConstants.CUSTOMER}/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['aquisition']
        }),
        assignFieldDropdownList: builder.query<any, any>({
            query: () => ({
                url: urlConstants.FIELD_EMPLOYEE_DROPDOWN,
                method: 'GET',
            }),
            providesTags: ['aquisition-dropdown']
        }),
        sendOtp: builder.mutation<any, any>({
            query: (phoneNumber: any) => ({
                url: urlConstants.SENDOTP,
                method: 'POST',
                body: { phoneNumber }
            })
        }),
        validateOtp: builder.mutation<any, any>({
            query: ({ phoneNumber, otp }: any) => ({
                url: urlConstants.VALIDATEOTP,
                method: 'POST',
                body: { phoneNumber, otp }
            })
        }),
        getCustomerDuplicates: builder.query<any, any>({
            query: ({ id }: any) => ({
                url: `${urlConstants.DUPLICATES}/${id}`,
                method: 'GET',
            }),
        }),
        getAquisitionFormQuestions: builder.query<any, void>({
            query: () => ({
                url: urlConstants.ACQUISITION_FORM_QUESTION,
                method: 'GET',
            }),
            providesTags: ['aquisition']
        }),
        getCustomerBasicInfo: builder.query<any, { id: string }>({
            query: ({ id }) => ({
                url: `${urlConstants.CUSTOMER}/basicInfo`,
                method: 'GET',
                params: { id }
            }),
            providesTags: ['aquisition']
        }),
        addReview: builder.mutation<any, any>({
            query: (reviewData: any) => ({
                url: urlConstants.REVIEW_ADD,
                method: 'POST',
                body: reviewData
            }),
            invalidatesTags: ['aquisition']
        }),
        getExistingReview: builder.query<any, { id: string }>({
            query: ({ id }) => ({
                url: `${urlConstants.REVIEW_GET}?id=${id}`,
                method: 'GET',
            }),
            providesTags: ['aquisition']
        }),
        updateAdminRemarks: builder.mutation<any, { adminRemarkId: string; adminRemarks: string }>({
            query: ({ adminRemarkId, adminRemarks }) => ({
                url: `${urlConstants.ADMIN_REMARKS}/${adminRemarkId}`,
                method: 'PUT',
                body: { adminRemarks },
            }),
        }),
        sendWhatsAppFeedbackRequest: builder.mutation<any, { id: number }>({
            query: ({ id }) => ({
                url: `${urlConstants.CUSTOMER}${urlConstants.WHATSAPP_FEEDBACK_REQ}`,
                method: 'POST',
                body: { id }
            })
        }),
    })
})

export const {
    useCreateAcquisitionMutation,
    useGetAquisitionDropdownsDetailsQuery,
    useGetUserSalesDropdownsDetailsQuery,
    useEditAcquisitionSFMutation,
    useGetAcquisitionListQuery,
    useEditAcquisitionMutation,
    useGetSingleCustomerQuery,
    useDeleteAcquisitionMutation,
    useAssignFieldDropdownListQuery,
    useSendOtpMutation,
    useValidateOtpMutation,
    useGetCustomerDuplicatesQuery,
    useGetAquisitionFormQuestionsQuery,
    useGetCustomerBasicInfoQuery,
    useAddReviewMutation,
    useGetExistingReviewQuery,
    useUpdateAdminRemarksMutation,
    useSendWhatsAppFeedbackRequestMutation
} = AquisitionApi
